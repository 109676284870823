@import '../../variables.scss';

.contact-form {
  justify-content: center;
  overflow: auto;
  margin: auto;
  flex: 1;
  width: 100%;
}

@media (min-width: $desktop-width) {
  .contact-form {
    max-width: 542px;

    h1 {
      font-size: 2.75rem;
      font-weight: 700;
    }
  }

  .back-button {
    position: static;
    bottom: unset;
    padding-bottom: 2em;
  }
}

@media (min-width: $tablet-width) {
  .contact-form {
    max-width: 542px;

    h1 {
      font-size: 2.75rem;
      font-weight: 700;
    }
  }

  .back-button {
    position: static;
    bottom: unset;
    padding-bottom: 2em;
  }
}
