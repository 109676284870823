@import '../../variables.scss';
@import "constants/zIndex";

#static {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  .container {
    position: absolute;
    bottom: 50%;
    width: 100%;
    transform: translateY(75px);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 0 20px;
    margin: 0;

    h1 {
      margin: auto;
      max-width: 300px;
      background: linear-gradient(
        283.48deg,
        #ffcc4d 29.92%,
        #e4981d 54.77%,
        #fff69d 78.08%,
        #c06719 99.31%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: #693510;
    }
    h2 {
      max-width: 450px;
      margin: 20px 0;
    }
    .returnButton {
      width: 195px;
      height: 62.5px;
      display: flex;
      margin: auto;

      span {
        width: 100%;
      }
    }
    .coinsAnimation {
      position: absolute;
      transform: scale(0.5);
      width: 1024px;
      top: -250px;
    }
  }

  & > img {
    position: absolute;
    bottom: 0;
    z-index: $z-index-base;

    &.giraff {
      height: 250px;
      right: 0px;
    }

    &.lion {
      position: fixed;
      left: 60%;
      bottom: 8%;
      height: 220px;
    }

    &.island {
      position: fixed;
      right: 50%;
      bottom: 0;
      width: 170px;
    }

    &.pig {
      height: 320px;
      right: 10%;
    }

    &.bear {
      width: 50%;
      height: unset;
      left: 10%;
    }
  }

  .shop-footer {
    position: absolute;
    bottom: 0;
    padding: 0;
  }
}

@media (min-width: $tablet-width) {
  #static {
    .container {
      h1 {
        max-width: 500px;
      }
    }
    & > img {
      &.giraff {
        height: 340px;
      }
    }
  }
}

@media (min-width: 2000px) {
  #static img.island {
    width: 32%;
    right: 45%;
  }
}
