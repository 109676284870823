@import '../../variables.scss';
@import 'constants/zIndex';

@keyframes shopFadeIn {
  to {
    opacity: 1;
  }
}

@keyframes bundlesEnter {
  to {
    transform: translateY(0px);
  }
}

#shop {
  width: 100%;
  animation: shopFadeIn 0.5s linear forwards;
  opacity: 0;
  height: 100%;
  position: relative;
  z-index: $z-index-base;
}

.bundle-top-container {
  width: 100%;
  height: 100%;
}

.storeWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  width: 100vw;
  margin-bottom: 32px;

  // Default behavior to prevent system dark theme to affect our components
  color-scheme: light;
}

// NEW LAYOUT //

.offers-wrapper {
  // DO NOT ADD PADDING HERE - IT WILL BREAK THE PROMOTIONS CAROUSEL
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: $z-index-base;
  flex-grow: 1;

  &--column-view {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 16px;
    padding-right: 16px;
    margin-top: 4px;

    & > div {
      &:hover {
        cursor: pointer;
      }
    }
  }

  .hug .carousel-item {
    padding: 6px;
  }

  .baba .carousel-item {
    padding: 6px;
  }

  .locobingo .carousel-item {
    padding: 6px;
  }
}

.bundles-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  padding: 0 16px;
  user-select: none;
  -webkit-tap-highlight-color: transparent;

  &.play-simple {
    margin-top: 0;
  }

  & > *:not(:last-child) {
    margin-bottom: 24px;
  }
}

.rolling-popups-wrapper {
  margin-top: 5px;
}

.rolling-offers-wrapper {
  margin-top: 5px;
  width: 100%;
  padding: 0 16px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: $z-index-base;
  flex-grow: 1;
  box-sizing: border-box;

  & > div {
    width: 100%;
  }

  & > div:not(:last-child) {
    margin-bottom: 12px;
  }
}

.offers-wrapper-size-limiter {
  height: 100%;
  width: 100%;
  max-width: 1248px;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  .bundle {
    margin-top: 16px;
    max-width: 416px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    border-radius: 8px;
    @at-root .single .bundle {
      width: 33%;
    }
  }
}

.load_more_box {
  width: 100%;
  display: flex;
  justify-content: center;
}

@media only screen and (min-width: 768px) {
  .special-bonus-carousel {
    background: rgba(0, 0, 0, 0.5);
    padding-bottom: 32px;
    margin-top: 16px;
    .special-bonus-carousel-nav {
      .dot {
        margin-top: 16px;
      }
    }
  }
}

.player-level-image {
  width: 100%;
  max-width: 1248px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 8px 8px 8px;
  margin: 0px auto;

  img {
    height: 50px;
    &:first-child {
      height: 70px;
    }

    @media only screen and (min-width: 768px) {
      height: 60px;
    }
  }
}

.special-free-offer {
  &__fail-screen {
    font-family: var(--font-family);
    width: 100vw;
    height: 100vh;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
    background-color: rgba(0, 0, 0, 0.8);
    position: fixed;
    z-index: $z-index-extreme;
    color: white;
    gap: 1em;

    h2 {
      font-size: 2rem;
    }

    &__action-button {
      font-size: 18px;
      text-shadow: 0 1px 1px black;
      box-shadow: inset 0 -4px 0 rgba(0, 0, 0, 0.3);
    }
  }
}

.screen-lock {
  overflow: hidden !important;
}
