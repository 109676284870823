@import '../../variables.scss';

.login-template {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-grow: 1;
}

.ac-login-page {
  font-family: var(--font-family);
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding-top: 30px;
  align-items: center;
  width: 100%;
  flex-grow: 1;

  @media (width >= $desktop) {
    min-height: 100%;
  }

  &--ios13 {
    height: 100%;
  }

  img {
    max-width: 100%;
    max-height: 100%;
    height: auto;
  }

  &__logo {
    margin: 2vh 0;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 740px;

    img {
      @media (width <= $tablet) {
        max-width: 75%;
      }
    }
  }

  &__top-container {
    max-width: 100%;
    @media (width < $desktop) and (height >= 500px) {
      justify-content: flex-end;
      margin: 30% 0 20px;
      padding: 0 10px;
    }
  }

  &__sale-image {
    max-width: 400px;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2vh;

    img {
      height: auto;
      max-height: 8vh;
    }
  }

  &__how-it-works {
    max-height: 40px;
    margin-top: 20px;
  }
}

.ac-login-page__footer-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;

  &__image {
    width: 100vw;
  }

  @mixin footer-style($bg-color, $text-color) {
    background-color: $bg-color;

    .login-terms-text,
    .contact-support-button {
      color: $text-color;
    }
  }

  &__footer-text {
    font-family: var(--font-family);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 12px;
    padding: 10px 0;

    @mixin footer-style($bg-color, $text-color) {
      background-color: $bg-color;

      .login-terms-text,
      .contact-support-button {
        color: $text-color;
      }
    }

    @include footer-style(unset, white);
  }

  @media (width < $desktop) and (orientation: portrait) {
    width: 100%;
    max-width: 430px;

    img {
      max-height: unset;
      width: 100%;
    }

    &__footer-text {
      padding-top: 1vh;
      padding-bottom: 3vh;

      &.big-fish-casino {
        @include footer-style(#8999cb, #100c29);
      }

      &.jackpot-slots {
        @include footer-style(#a589cb, #210e3c);
      }
    }
  }
}

@media screen and (max-width: 1023px) and (orientation: landscape) {
  .ac-login-page {
    height: 100%;
  }
}

.backButton {
  color: white;
  font-size: 16px;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  padding-bottom: 2em;
}

.otp-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  width: 100%;

  p {
    margin: 0px;
    color: white;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 0.00938em;
  }

  .qr {
    min-width: 80px;
    min-height: 80px;
    padding: 4px 4px 0 4px;
    background-color: white;
    box-sizing: content-box;
  }
}
