@import '@appcharge/shared-ui/lib/styles.css';

html {
  padding: env(safe-area-inset);
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
}
