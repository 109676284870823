@import "constants/zIndex";

dialog {
  max-width: 500px;
  width: 60vw;
  padding: 2em;
  text-align: center;
  z-index: $z-index-critical;
  margin-top: 2em;
  font-family: 'Roboto';
  code {
    font-size: 2em;
  }
  form {
    margin-top: 2em;
  }
  textarea {
    resize: none;
  }
}
