@import '../../variables.scss';
@import "constants/zIndex";

.transparentOverlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: $z-index-modal;
  background-color: rgba($color: #000000, $alpha: 0.5);
}

.infoModal {
  position: relative;
  width: 94%;
  height: 70%;
  margin-bottom: 32px;
  z-index: $z-index-modal;
  background-color: white;
  color: white;
  padding: 8px;
  padding-top: 30px;

  span {
    font-family: 'roboto';
    position: absolute;
    color: black;
    right: 8px;
    top: 10px;
    cursor: pointer;
  }
  h3 {
    margin-bottom: 24px;
  }
  iframe {
    border: 0;
  }
}

@media (min-width: $tablet-width) {
  .infoModal {
    width: 80%;
    height: 80%;
    max-width: 1000px;
    max-height: 800px;
    padding: 24px;
    padding-top: 60px;
    margin-bottom: 0;

    span {
      right: 24px;
      top: 20px;
    }
  }
}
