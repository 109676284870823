@import "constants/zIndex";

.login__webview-escape-screen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;
  z-index: $z-index-overlay;

  &__title {
    font-size: 24px;
    font-weight: 700;
  }

  &__text {
    display: block;
    margin: 1em 0;
  }

  .webview-button {
    border: 1px solid white;
    background: #0097fe;

    &:disabled {
      background: #717188;
    }
  }

  &__instructions {
    font-family: 'Open Sans', sans-serif;
    position: fixed;
    bottom: -1px;
    margin: unset;
    list-style: none;
    background: #2b2b2b;
    padding: 16px;
    width: 100%;
    color: white;
    text-align: start;
    border-radius: 8px 8px 0 0;

    &__item {
      display: flex;
      align-items: center;
      padding: 5px;

      &__icon {
        margin-right: 16px;
        height: 18px !important;
        width: 18px !important;
      }
    }
  }
}
