html, body {
  width: 100%;
  height: 100dvh; /* Forces dynamic viewport height */
  overflow: hidden;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: black;
}

@supports (padding-bottom: env(safe-area-inset-bottom)) {
  body {
    padding-bottom: 0 !important;
  }
}
