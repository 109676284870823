.checkout-failed {
  font-family: var(--font-family);
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;

  &__close-icon {
    width: 100%;
    display: flex;
    justify-content: end;
  }

  &__payment-reference {
    margin-top: 20px;
  }
}
